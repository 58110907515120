<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100 d-flex justify-content-end">
            <md-button
              class="md-primary mr-2"
              @click="onSortItems"
            >
              <md-icon class="mr-2">
                low_priority
              </md-icon>
              Modify Order
            </md-button>

            <md-button
              class="md-primary mr-2"
              @click="onCreate"
            >
              <md-icon class="mr-2">
                add
              </md-icon>
              New Item
            </md-button>
          </div>
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getItems"
              @onEdit="onEdit"
              @onDelete="onDelete"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <package-items-form
      v-if="selectedItem"
      :item="selectedItem"
      @close="closeModal"
      @save="$refs.vtable.init"
    />

    <OrderForm
      v-if="showSortModal"
      title="Sort Package Items"
      :index-url="$API.PACKAGE_ITEMS"
      :update-url="`${$API.PACKAGE_ITEMS}update/order`"
      primary-key="package_item_id"
      order-key="show_order"
      @close="showSortModal = false"
      @save="$refs.vtable.init"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import OrderForm from '@/components/OrderForm.vue';
import PackageItemsForm from './PackageItemsForm.vue';

export default {
  components: {
    VueTable,
    OrderForm,
    PackageItemsForm,
  },
  props: [],
  data() {
    return {
      showSortModal: false,
      selectedItem: null,
      vTable: {
        headers: [
          {
            title: 'name',
            mask: 'name',
            sortable: true,
          },
          {
            title: 'default_price',
            mask: 'default price',
            pre: '€',
            sortable: true,
          },
          {
            title: 'units',
            mask: 'default units',
            sortable: true,
          },
          {
            title: 'required',
            mask: 'Is Required',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'category.commissionable',
            mask: 'Is Commissionable',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
          {
            title: 'fixed_price',
            mask: 'Fixed Price',
            sortable: true,
            boolean: true,
            booleanValues: {
              true: 'YES',
              false: 'NO',
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Item',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Item',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getItems(params) {
      this.request(this.$API.PACKAGE_ITEMS.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onEdit(item) {
      this.selectedItem = { ...item };
    },
    onCreate() {
      this.selectedItem = {
        package_item_id: null,
        package_item_category_id: null,
        name: null,
        default_price: null,
        units: 1,
        required: false,
        fixed_price: false,
      };
    },
    closeModal() {
      this.selectedItem = null;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting Package Item',
        `Are you sure you want to delete this Package Item "${item.name}"?`,
      )
        .then(() => {
          this.request(`${this.$API.PACKAGE_ITEMS}${item.package_item_id}`, 'delete', {}, () => {
            this.fireSuccess('Item deleted successfully.');
            // Reset Table after response
            this.$refs.vtable.init();
          });
        })
        .catch(() => {});
    },
    onSortItems() {
      this.showSortModal = true;
    },
  },
};
</script>

<style scoped>
</style>
