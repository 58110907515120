<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Package Items Form
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <form-text
          v-model="editableName"
          icon="description"
          label="Name"
          rules="required"
        />
        <label
          class="name-disclaimer"
          :class="{'name-disclaimer--has-error': nameHasCode}"
        >
          * Please refrain from entering the package item code
          manually as it is automatically generated by the system.
        </label>
        <form-number
          v-model="form.default_price"
          icon="euro"
          label="Default Price"
          rules="required"
        />
        <form-number
          v-model="form.units"
          icon="tag"
          label="Default Units"
          rules="required"
        />
        <PackageItemCategoriesSelect
          v-model="form.package_item_category_id"
          :options="categories"
          rules="required"
          @change="onCategorySelected"
        />
        <md-switch
          v-model="form.commissionable"
          disabled
        >
          Commissionable
        </md-switch>
        <md-switch v-model="form.required">
          Required
        </md-switch>
        <md-switch v-model="form.fixed_price">
          Fixed Price
        </md-switch>
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-success float-right"
        @click="saveItem"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormNumber,
  FormText,
} from '@/components/Inputs';
import {
  PackageItemCategoriesSelect,
} from '@/components/Inputs/selects';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', required);

const pickCodeRegex = /^(\d+\s*-\s*)/;
const pickNameRegex = /^\d+\s*-\s*(.*)/;

export default {
  components: {
    FormNumber,
    FormText,
    PackageItemCategoriesSelect,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        package_item_id: null,
        package_item_category_id: 'none',
        commissionable: false,
        name: null,
        default_price: null,
        units: 1,
        required: false,
        fixed_price: false,
      }),
    },
  },
  data() {
    return {
      form: {
        package_item_id: null,
        package_item_category_id: null,
        commissionable: false,
        name: null,
        default_price: null,
        units: 1,
        required: false,
        fixed_price: false,
      },
      nameHasCode: false,
    };
  },
  computed: {
    namePrefix() {
      if (!this.form.name) return '';
      const match = this.form.name.match(pickCodeRegex);
      return match ? match[1] : '';
    },
    editableName: {
      get() {
        if (!this.form.name) return '';
        const match = this.form.name.match(pickNameRegex);
        return match ? match[1] : this.form.name;
      },
      set(value) {
        const prefix = this.namePrefix;
        const match = value.match(pickNameRegex);
        this.nameHasCode = !!match;
        this.form.name = prefix + value;
      },
    },
  },
  mounted() {
    this.form = { ...this.item };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveItem() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.form.package_item_id) {
            this.request(this.$API.PACKAGE_ITEMS + this.form.package_item_id, 'put', this.form, () => {
              this.fireSuccess('Package Item Updated Succesfully');
              this.$emit('save');
              this.close();
            });
          } else {
            this.request(this.$API.PACKAGE_ITEMS, 'post', this.form, () => {
              this.fireSuccess('Package Item Created Succesfully');
              this.$emit('save');
              this.close();
            });
          }
        }
      });
    },
    onCategorySelected(cat) {
      this.form.commissionable = cat.commissionable;
    },
  },
};
</script>
<style lang="scss" scoped>
.md-switch:not(.md-disabled) ::v-deep .md-switch-label {
  color: rgba(0, 0, 0, .75) !important
}

.name-disclaimer {
  padding-left: 2.25rem;
  margin-bottom: 1rem;
  display: block;
  font-size: 12px;

  &--has-error {
    color: var(--danger);
  }
}
</style>
